<template>
  <v-img
      v-if="!title && !titleRaw"
      :height="height" :max-width="height"  src="/library/img/westudy_logo_small.png" />
  <div v-else class="d-flex align-center">
    <v-img
        :height="height" :width="height" :max-width="height"  src="/library/img/westudy_logo_small.png" />
    <v-divider style="border-width: 1px; margin: 0 10px" :style="`border-color: ${wsDARKLIGHT}`"  vertical></v-divider>
    <h4 class="wsACCENT">{{ title ? $t(title) : titleRaw }}</h4>
  </div>
</template>

<script>
export default {
  name: "wsLogo",
  props : {
    height : {
      type : String,
      default : '32'
    },
    title : {
      type : String
    },
    titleRaw : {
      type : String
    }
  }
}
</script>

<style scoped>

</style>